/**
 * Config
 * Variables and utilities
 */
@import 'config/vars',
        'config/utils';

/**
 * Core
 * Base styles
 */
@import 'core/reset',
        'core/typography',
        'core/form';