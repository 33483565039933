/**
 * core/form
 */

.form-item,
.form-actions {
  margin: 8px;
  display: inline-block;
}

/* defaults */
input {
  background: none;
  border: 1px solid #b0b3b4;
  border-radius: 0;
  color: #919597;
  font-size: 18px;
  height: 38px;
  letter-spacing: 0.05em;
  padding: 0 14px;
  transition: all .1s linear;
  -webkit-transition: all .1s linear;
  -moz-transition: all .1s linear;
  width: 200px;
  outline: none;
}

input:focus {
  border-color: #898d8f;
}

/* submit button, regular blue button */
input[type="submit"] {
  background: $red;
  border: none;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  color: #fff;
  cursor: pointer;
  height: 54px;
  text-align: center;
  -webkit-appearance: none;
  border-radius: 8px;
  text-transform: uppercase;
  @include trans(0.2s);

  &:hover {
    background: $red_hover;
  }
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  height: 54px;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid #cfcece;
  background-color: $white;
  color: $font-gray;
  -webkit-appearance: none;
}


//checkbox style
input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  background-image: url("../img/checkbox-sprite.png");
  background-repeat: no-repeat;
  height: 30px;
  display: block;
  line-height: 30px;
  padding-left: 40px;
}

input[type="radio"]:checked + label {
  background-position: 0 -40px;
}

// nice scroll styles

.nicescroll-rails {
  > div {
    width: 6px !important;
    margin-right: 25px;
    border: none !important;
    background-color: $gray-2 !important;
  }
}

/**
 * Speak out form
 */
.speak-out-block, .not-front #webform-client-form-1989 {

  .speak-out-title span,
  .speak-out-desc {
    color: #a6acb6;
  }

  input[name="submitted[first_name]"] {
    width: 300px;
  }
  input[name="submitted[last_name]"] {
    width: 400px;
  }
  input[name="submitted[email]"] {
    width: 500px;
  }
  input[name="submitted[zip_code]"] {
    width: 200px;
  }

  .form-actions {
    width: 150px;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    background-color: $red;

    &::before {
      content: "";
      background-image: url("../img/homepage-sprite.png");
      background-repeat: no-repeat;
      background-position: 48px -74px;
      height: 70px;
      left: 0;
      position: absolute;
      top: 70px;
      width: 100%;
      cursor: pointer;
    }

    input[type="submit"] {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-sizing: border-box;
      padding-top: 55px;
    }
  }

}

.not-front #webform-client-form-1989 {

  input[name="submitted[first_name]"] {
    width: 100%;
  }
  input[name="submitted[last_name]"] {
    width: 100%;
  }
  input[name="submitted[email]"] {
    width: 100%;
  }
  input[name="submitted[zip_code]"] {
    width: 100%;
  }
}

.speak-out-title {
  span {
    margin-left: 10px;
  }
}

.speak-out-desc {
  font-size: 16px;

  .red {
    color: #ef0325;
  }
}

/* Newsletter Signup Form */
.signup {

  padding: 28px 0;

  input[type="text"],
  input[type="email"],
  input[type="password"] {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    border-radius: 8px;
    box-shadow: inset 2px 2px 4px 0px $font-gray;
    border: none;
  }
  .form-item,
  .form-actions {
    margin: 0;
  }
}

@media all and (max-width:1023px) {
  .speak-out-block {
    padding: 30px 65px !important;

    .form-item {
      input[type="text"],
      input[type="email"],
      input[type="password"] {
        width: 100%;
      }
    }
    // .form-item:nth-child(2),
    // .form-item:nth-child(3),
    // .form-item:nth-child(4),
    // .form-item:nth-child(5) {
    //   float: none;
    //   max-width: none;
    //   margin: 0;
    //   position: static;
    // }
  }

  .speak-out-block, .node-webform form#webform-client-form-1989 {

    .form-actions {
      position: relative;
      margin: 25px auto;
      height: 90px;
      width: 165px;
      border-radius: 10px;
      padding: 0 15px;
      box-sizing: initial;

      &:before {
        top: 10px;
        left: initial;
        right: 0;
        width: 117px;
      }

      input[type="submit"] {
        margin-top: 0;
        text-align: left;
        padding-top: 0;
      }
    }
  }
}
