html.ie8 {
  body {
    input[type="text"] {
      line-height: 54px;
    }
    header {
      .main-wrap {
        #header-right {
          .region-header {
            #block-poll-recent {
              .poll {
                .vote-form {
                  .choices {
                    width: 100%;
                  }
                }
              }
            }

            #block-search-form {
              input[type="text"] {
                background-image: url("../img/white_square.png");
                right: 55px;
                color: $font-black;
              }
            }
          }
        }
      }
    }

    #block-views-hp-news-feed-block {
      .view-more {
        &:hover {
          a {
            width: 0px;
          }
        }
      }
    }

    .region-below {
      #block-webform-client-block-1 {
        input[type="submit"] {
          width: 50px;
        }
      }
    }
  }

  header {
    background-image: url("../img/square.png");
  }
}