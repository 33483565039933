/**
 * core/reset
 */


html, body {
  height: 100%;
  color: $font-black;
}



* {
  box-sizing: border-box;
}

/* Iframe
---------------------------------------------------------------- */

iframe {
  display: block;
  margin: 0;
  padding: 0;
}


/* Images
---------------------------------------------------------------- */

img {
  display: block;
  margin: 0;
  padding: 0;
}

