/* config/_vars (does not output css) */
//greyscale
$white: #fff;
$black: #000;

//gen
$brand: #8790A5;
$brand_alt: darken($brand, 10);
$body_bg: $white;


//links
$link_colour: #428bca;
$link_hover_colour: darken($link_colour, 20);


//focus
$focus_border: rgba(82, 168, 236, 0.8);


//factory
@function ColourConstructor ($perc, $colour) {
  @if $perc > 0 {
    @return darken($colour, $perc)
  }
  @if $perc < 0 {
    @return lighten($colour, $perc * -1)
  }
  @if $perc == 0 {
    @return $colour
  }
}


//functions
@function Greyscale ($perc: 50) {
  @return darken(#fff, $perc)
}


//boxes and text
$text_colour: ColourConstructor(80, $white);
$litetext_colour: lighten($text_colour, 50);

//alets
$default: Greyscale(25);
$info: $link_colour;
$success: #5cb85c;
$warning: #f0ad4e;
$error: #d9534f;

$base_size: 62.5%;
$display: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$sans_serif: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$serif: "Georgia", "Times New Roman", Times, serif;
$code: Menlo,Monaco,Consolas,"Courier New",monospace;
//$serif: "Palatino Linotype", "Book Antiqua", Palatino, serif;

$global_family: $sans_serif; //not everything inherits from the body...so we have $global_family

$text_size: 1.6em;
$text_line_height: 1.6em;

$h1: 4.8em;
$h2: 3.6em;
$h3: 2.8em;
$h4: 2.2em;
$h5: 1.8em;
$h6: 1.4em;

$lists_font_size: $text_size;
$lists_line_height: $text_line_height;

/* end */

/* NASE */

// color variables
$footer-red: #cd0a29;
$footer-gray: #dcdcdc;

$red: #ef0325;
$red_hover: #CD0A29;

$gray-1: #f0f0f0;
$gray-2: #bdbdbd;
$gray-3: #dcdcdc;
$gray-4: #cacaca;
$gray-5: #cfcece;
$panel-background: #2c2c2c;

$font-black: #323232;
$font-black-hover: #1B1B1B;
$font-gray: #a6acb6;
$font-gray-2: #626262;
$font-gray-quote: #b1afaf;
$font-red: #eb0126;

$red-cancer: #ff0025;
$red-diabetes: #ef0325;
$red-heart-disease: #cd0a29;
$red-obesity: #aa0a23;
$red-cancer-hover: #EF0325;
$red-diabetes-hover: #CD0A29;
$red-heart-disease-hover: #AA0A23;
$red-obesity-hover: #9C0A21;

// widths and heights

$full-width: 100%;
$page-width: 1020px;

$social-link-sprites: 31px;

// font sizes

$f22: 22px;
$f18: 18px;

// mixins
@mixin trans($duration) {
  -webkit-transition: all $duration linear;
  transition: all $duration linear;
}

@mixin box-shadow {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}

@mixin button {
  border-radius: 10px;
  background-color: $red;
  color: $white;
  text-transform: uppercase;
  font-size: 18px;
  padding: 15px 0px;
  display: block;
  text-align: center;
  @include trans(0.2s);

  &:hover {
    background-color: $red_hover;
  }

  @media all and (max-width: 767px) {
    max-width: 260px;
    margin: auto;
    left: 0;
    right: 0;
  }
}

@mixin rotate($degree) {
  -webkit-transform: rotate($degree);
  -moz-transform: rotate($degree);
  -ms-transform: rotate($degree);
  -o-transform: rotate($degree);
  transform: rotate($degree);
}

@mixin user_select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}

@mixin content_text {
  font-family: "ff-tisa-web-pro";
  font-weight: initial;
  font-size: 18px;

  h2 {
    font-family: "fira-sans";
    font-weight: 500;
    font-size: 26px;
    text-transform: uppercase;
  }

  p {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }

  blockquote {
    border-top: 1px solid $gray-5;
    border-bottom: 1px solid $gray-5;
    padding-top: 55px;
    padding-bottom: 30px;
    text-align: center;
    margin: 0;
    position: relative;
    font-family: "source-sans-pro";
    font-weight: 600;
    font-style: italic;
    font-size: 26px;
    color: $font-gray;
    margin: 0;
    p {
      font-family: "source-sans-pro";
      font-weight: 600;
      font-style: italic;
      font-size: 26px;
      color: $font-gray;
      margin: 0;

    }
    h4 {
      font-family: "source-sans-pro";
      font-weight: 900;
      color: $font-red;
      font-size: 14px;
      text-transform: uppercase;
      margin: 23px 0 0;
      font-style: initial;
    }

    &:before {
      content: "“";
      color: red;
      font-family: "source-sans-pro";
      font-weight: 900;
      font-size: 34px;
      position: absolute;
      top: 12px;
      width: 100%;
      left: 0;
    }
  }

  a {
    color: $font-red;
    font-family: "ff-tisa-web-pro";
    font-weight: 700;
    font-style: italic;
    text-decoration: underline;
  }

  ul {
    padding-left: 55px;
    li {
      list-style-type: none;
      list-style-image: url("../img/red-square.png");
      font-family: "source-sans-pro";
      color: $font-gray;
      font-size: 18px;
      padding-left: 8px;
    }
  }
}

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}


/* Sticky footer SASS mixin
 * I took the code from https://css-tricks.com/snippets/css/sticky-footer/ and modified it for SASS
 *
 * variables:
 * $footer-height - height of you footer. Good example: 90px, 45px | Bad example: "90px", 40..
 * $footer-name - name of your footer, you can use anything here. Example: "footer", ".footer", "#footer", ".myfooter"...
 * $page-name - name of your page container, basicly the container that goes from the top to footer, not including it.
 * You can use anything here. Example: "page", ".page", "#page", ".mypage"...
 * $container-name - name of you main wrapping container. It can be body tag, or any custom name/container you use.
 * Example: "body", ".container", "#container"...
 *
 */

@mixin stickyfooter ($footer-height, $footer-name, $page-name, $container-name) {
  #{$container-name} {
    height: 100%;
  }

  #{$page-name} {
    min-height: 100%;
    /* equal to footer height */
    margin-bottom: -#{$footer-height};
  }
  #{$page-name}:after {
    content: "";
    display: block;
  }
  #{$footer-name}, #{$page-name}:after {
    height: $footer-height;
  }
}

@mixin horizontal_background {
  background-image: url("../img/container-background-hor.png");
  background-repeat: repeat-x;
  background-position: 0 0;
  padding-top: 60px;
  margin-top: 60px;
  background-color: $gray-1;
  position: relative;
  width: 660px;
  margin-bottom: 0;

  &:before,
  &:after {
    background-image: url("../img/container-background-hor.png");
    background-repeat: repeat-x;
    background-position: 0 0;
    content: "";
    position: absolute;
    top: 0;
    width: 1000px;
    height: 100%;
    background-color: $gray-1;
  }

  &:after {
    right: -1000px;
  }

  &:before {
    left: -1000px;
  }
}


/* END NASE */