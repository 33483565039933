///**
//* core/typography
//*/
//body, p, li, h1, h2, h3, h4, h5, h6, div, li, a {
//  color: #484e52;
//  font-size: 22px;
//  -webkit-font-smoothing: antialiased;
//  font-weight: 400;
//  letter-spacing: .03em;
//  line-height: 1.33em;
//}
//
//h1, h2, h3, h4, h5, h6,
//.section-name {
//  font-family: "Noticia Text", "Georgia", serif;
//  line-height: 1.1em;
//}
//
///* Page title, section name (inside of banner) */
//h1, .section-name {
//  font-size: 50px;
//}
//
///* Page title (on page, outside of banner) */
//.node h1.page-title {
//  font-size: 40px;
//}
//
///* Node teaser title */
//h2 {
//  font-size: 36px;
//}
//
///* Block title */
//h3 {
//  color: #23568e;
//  font-size: 22px;
//  font-weight: 700;
//}
//
///* Node subheading */
//h4 {
//  font-size: 22px;
//  font-weight: 700;
//  line-height: 1.37em;
//  padding: 38px 0 32px;
//}
//
//h4:first-child {
//  padding-top: 0;
//}
//
///* Viewblock node title field */
//h6 {
//  font-size: 20px;
//  line-height: 1.4em;
//}
//
//span, strong, a, em {
//  color: inherit;
//  font-family: inherit;
//  font-size: inherit;
//  font-style: inherit;
//  font-weight: inherit;
//  letter-spacing: inherit;
//  line-height: inherit;
//}
//
//em {
//  font-style: italic;
//}
//
//strong {
//  font-weight: 700;
//}
//
//p {
//  padding: 0 0 28px;
//}
//
//
///* List Items
//---------------------------------------------------------------- */
//
//li, ul.inline li {
//  padding: 0;
//}
//
//.region-content .node ul,
//.region-content .node ol {
//  padding: 0 0 28px;
//}
//
//.region-content .node li {
//  background: url("../img/li.png") no-repeat 4px 8px;
//  color: #484e52;
//  font-weight: 300;
//  margin: 10px 0 0;
//  padding: 0 0 0 40px;
//}
//
///* Blockquotes
//---------------------------------------------------------------- */
//blockquote {
//  border: 1px solid #dfdfdf;
//  float: left;
//  margin: 0 40px 0 0;
//  padding: 16px;
//  width: 225px;
//  p {
//    color: #3279c8;
//    font-family: "PT Sans", "Helvetica", "Arial", sans-serif;
//    font-size: 18px;
//    font-weight: 700;
//    line-height: 1.66em;
//    padding: 0;
//  }
//  &.fancy-quote {
//    float: none;
//    margin: 0 0 25px;
//    position: relative;
//    width: auto;
//    &:before {
//      background: url("../img/sprite.png") no-repeat -1px -84px;
//      bottom: -18px;
//      content: "";
//      display: block;
//      height: 18px;
//      left: 28px;
//      position: absolute;
//      width: 68px;
//    }
//    em {
//      font-size: 14px;
//      font-style: normal;
//      font-weight: 400;
//    }
//  }
//}
//
//
//
//// Links
//a {
//  color: #da4040;
//  text-decoration: none;
//}
//
//a:hover,
//.node .content a.button:hover {
//  text-decoration: none;
//}
//
//.node .content a:hover {
//  text-decoration: underline;
//}
//
//// Small text
//.small,
//.small li {
//  color: #8d9499;
//  font-family: "PT Sans", "Helvetica", "Arial", sans-serif;
//  font-size: 12px;
//}

html,
body {
  font-family: "source-sans-pro";
}

a {
  text-decoration: none;
  font-family: "fira-sans";
  font-weight: 500;
  color: $font-black;
}

a:visited {
  color: inherit;
}

p {
  font-weight: 600;
  font-size: 16px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  font-family: "fira-sans";
  font-weight: 500;
  font-size: 18px;
}

input[type="submit"] {
  font-family: "fira-sans";
  font-weight: 500;
  font-size: 18px;
}

h1, h2, h3, h4, a, p {
  color: $font-black;
}

h2, h3, h4 {
  font-family: "fira-sans";
  font-weight: 500;
}

h3, h4 {
  font-size: 22px;
}

h2 {
  text-transform: uppercase;
  font-size: 26px;
}


//* TisaPro BoldIta: font-family: "ff-tisa-web-pro"; font-weight: 700; font-style: italic;
//* SourceSansPro Regular: font-family: "source-sans-pro"; font-weight: 400;
//* SourceSansPro SemiBold: font-family: "source-sans-pro"; font-weight: 600;
//* SourceSansPro SemiBoldIt: font-family: "source-sans-pro"; font-weight: 600; font-style: italic;
//* SourceSansPro Black: font-family: "source-sans-pro"; font-weight: 900;
//* TrajanPro3 Bold: font-family: "trajan-pro-3"; font-weight: 700;
//* FiraSans Medium: font-family: "fira-sans", font-weight: 500;
//* FiraSans SemiBold: font-family: "fira-sans", font-weight: 600;