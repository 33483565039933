body {
  margin: auto;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  opacity: 1;

  #container {
    width: $full-width;
    margin: auto;

    .region-hero {
      width: $full-width;
      //height: 575px;
      overflow: hidden;
      background-size: cover;
      background-position: center;


      img {
        width: 100%;
        height: auto;
        display: none;


        &.max_height {
          width: auto;
          height: 100%;
        }

        &.max_width {
          width: 100%;
          height: auto;
        }

      }

      #hero-container {
        height: initial !important;
      }
    }

    #page {
      position: relative;
      overflow: hidden;
      .main-wrap {
        width: $page-width;
        margin: 0 auto;
        padding: 0 10px;
        position: relative;

        .featured-wrapper {
          display: none;
          .region-featured {
            margin-top: 40px;
          }
        }
      }
    }
  }

  &.front {
    #container .region-hero {
      height: 575px;
    }

    .column-wrapper {
      width: $full-width;
      display: block;
      padding-top: 115px;
      background-image: url("../img/container-background-hor.png");
      background-color: $gray-1;
      background-position: 0 0;
      background-repeat: repeat-x;
      overflow: hidden;

      .region-columns {
        width: $page-width;
        margin: auto;
        padding: 0 10px;
      }
    }
  }

  &.not-front {
    #container {
      .region-hero {
        height: 336px;
      }
      
      #page {
        .main-wrap {
          .featured-wrapper {
            margin-bottom: 125px;
          }
        }
      }
    }
  }
  
  &.page-latest-news,
  &.page-resources,
  &.page-pfcd-in-the-states {
    #container {
      #page {
        .main-wrap {
          .featured-wrapper {
            display: block;
          }
        }
      }
    }
  }

  div.section-title {
    width: $page-width;
    margin: auto;
    position: relative;

    h2.section-title {
      font-family: "fira-sans";
      font-weight: 500;
      font-size: 54px;
      color: $white;
      text-shadow: 0px 0px 10px $black;
      position: absolute;
      top: -180px;
    }
  }
}

// pagination

.item-list .pager {
  //background-image: url("../img/container-background-hor.png");
  //background-repeat: repeat-x;
  //background-position: 0 0;
  background-color: $gray-1;
  position: relative;
  width: 660px;
  margin-bottom: 0;
  height: 57px;

  &:before,
  &:after {
    //background-image: url("../img/container-background-hor.png");
    //background-repeat: repeat-x;
    //background-position: 0 0;
    content: "";
    position: absolute;
    top: 0;
    width: 1000px;
    height: 100%;
    background-color: $gray-1;
  }

  &:after {
    right: -1000px;
  }

  &:before {
    left: -1000px;
  }


  li {
    display: inline-block;
    float: left;
    a {
      color: $font-gray;
      font-family: "source-sans-pro";
      font-weight: 500;
      font-size: 14px;
      font-weight: bold;
      display: inline-block;

      &:hover {
        color: $font-red;
      }
    }

    &.pager-current {
      color: $font-gray;
      text-decoration: underline;
      font-family: "source-sans-pro";
      font-weight: bold;
      font-size: 14px;
      cursor: default;
    }

    &.pager-previous a {
      background-image: url("../img/pager-sprite.png");
      background-repeat: no-repeat;
      background-position: 0 -32px;
      display: inline-block;
      height: 17px;
      width: 30px;
      padding: 0 15px;
      color: transparent;

      &:hover {
        background-position: 0 -91px;
      }
    }

    &.pager-next a {
      background-image: url("../img/pager-sprite.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      display: inline-block;
      height: 17px;
      width: 30px;
      padding: 0 15px;
      color: transparent;

      &:hover {
        background-position: 0 -59px;
      }
    }
    
    &.pager-first,
    &.pager-last {
      display: none;
    }
  }
}

// social links

.service-links {
  ul {
    padding-left: 70px !important;
    margin: 0;
    position: relative;
    border-bottom: 1px solid $gray-5;

    &:before {
      content: "SHARE";
      font-family: "source-sans-pro";
      font-weight: bold;
      font-size: 14px;
      color: $font-red;
      position: absolute;
      top: 0;
      left: 0px;
    }
    li {
      list-style-type: none;
      display: inline-block;
      width: 30px;
      height: 30px;
      overflow: hidden;
      background-image: url("../img/social-sprite-red.png");
      background-repeat: no-repeat;
      padding: 0 !important;


      iframe {
        opacity: 0;
        top: -7px;
        left: -7px;
      }

      &.service-links-facebook-share {
        background-position: 0 0;
      }

      &.service-links-twitter-widget {
        background-position: 0 -41px;
      }
    }
  }
}

//tabs style

.logged-in .tabs {
  z-index: 100000;
  position: absolute;
  top: -14px;
 }

// admin menu fix

#admin-menu ul li a {
  font-size: 16px !important;
  line-height: 20px;

}


// Media queries

@media all and (max-width: 1023px) {
  $page-width: 660px;
  body {
    &.front {
      .column-wrapper {
        .region-columns {
          width: $page-width;
        }
      }
    }
  }
}

@media all and (max-width: 767px) {
  $page-width: 100%;
  body {
    &.front {
      #container {
        .column-wrapper {
          .region-columns {
            width: $page-width;
            padding: 0 15px;
          }
        }

        .region-hero {
          height: 480px;
        }
      }
    }

    div.section-title {
      h2.section-title {
        font-size: 30px;
      }
    }
  }


  .item-list .pager {
    width: 100%;
    li {
      margin: 0;
    }
  }
}